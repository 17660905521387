import { gql } from "@apollo/client";

export const wishlistFileds = `
            _id
            deliveryFee
            name
            status
            branch
            isPickup
            isPublic
            loyaltyCard
            celebrantFirstName
            celebrantLastName
            nameInGuestCard
            groomFirstName
            groomLastName
            summary
            branch
            type
            eventDate,
            eventAddress
            eventContact
            eventEmail
            createdDate,
            fb
            twitter
            instagram
            image
            wishlistCode
            subType
            deliveryAddress
            deliveryAddress2
            lng
            lat
            address1
            address2
            province
            city
            country
            zip
            deliveryDate
            deliveryVehicle
            deliveryQuote
            deliveryReference
            driverId
            shareLink
            deliveryStatus
            guestBarcode
            images{
                imageUrl
            }
            peers{
                _id
                firstName
                lastName
                email
                mobile
                hasAccepted
                hasRsvd
            }
            assignedBranch{
                name
            }
            wishlistItems{
                _id
                skuId
                commercialName
                productName
                skuBarcode
                skuCode
                babySkuCode
                primaryImage
                referencePrice
                currentPrice
                discount
                quantity
                bought
                imageUrl
                inventory
                reservations{
                    firstName
                    lastName
                    isOfflineOrder
                    quantity
                    reserveDate
                    checkoutDate
                    checkout
                }
            }
            customer{
                firstName
                lastName
                email
                status
            }
            history{
                user
                actionDate
                description
                data
            }
            offlineOrders{
                _id
                total
                firstName
                lastName
                email
                mobile
                isPickup
                referenceNo
                createdDate
                tid
                tr
                loyaltyCard
                wishlist
                discount
                status
                branch
                offlineItems{
                    _id
                    sku
                    babySkuCode
                    quantity
                    amount
                    currentPrice
                    skuBarcode
                    skuCode
                    productName
                    commercialName
                    image
                    packaging
                    dedication
                    remarks
                    branch
                    discount
                    compareAtPrice
                }
            }
            otp
            discountClaimed
`;

export const SEARCH_REGISTRY = gql`
  query searchRegistry($searchParam: SearchRegistryParam) {
    searchRegistry(searchParam: $searchParam)
  }
`;

export const GET_REGISTRY = gql`
    query getWishListbyId($id: String) {
        getWishListbyId(id: $id){
            ${wishlistFileds}
        }
    }
`;

export const GET_REGISTRY_FOR_PICKUP = gql`
  query getWishListPicklistById($id: String) {
    getWishListPicklistById(id: $id)
  }
`;

export const GET_ONLINE_ORDER_BY_WISHLIST_ID = gql`
  query getOnlineOrdersByWishlistId($id: String) {
    getOnlineOrdersByWishlistId(id: $id)
  }
`;